export default {
  changePassword: {
    title: "Please setup your new password",
    enterPassword: "Enter password",
    enterPasswordPlaceholder: "Enter your new password",
    confirmPassword: "Confirm password",
    confirmPasswordEmail: "An email has been sent, please confirm this password change",
    confirmPasswordPlaceholder: "Confirm new password",
    oldPassword: "Enter old password",
    oldPasswordPlaceholder: "Enter your old password",
  },
  common: {
    age: "Age",
    allOtherPlayersOfSame: " Benchmark settings",
    areYouSureYouWantToLogOut: "Are you sure you want to logout?",
    apply: "Apply",
    average: "Average",
    back: "Back",
    cancel: "Cancel",
    clear: "Clear",
    club: "Club",
    comparePlayers: "Compare player | Compare players",
    confirm: "Confirm",
    continue: "Continue",
    currency: "Currency",
    email: "Email",
    foot: "Foot",
    height: "Height",
    hide: "Hide",
    language: "Language",
    league: "League",
    loading: "Loading...",
    logout: "Log out",
    marketValue: "Market value",
    marketValueForecast: "Market Value Forecast",
    max: "max.",
    maximum: "Maximum",
    minimum: "Minimum",
    min: "min.",
    month: "Month",
    name: "Name",
    nationality: "Nationality",
    of: "of",
    okay: "Okay",
    playerInformation: "Player information",
    position: "Position | Positions",
    save: "Save",
    search: "Search",
    show: "Show",
    validate: {
      email: "Email is not valid",
      required: "This field is required",
      passwordsNotMatching: "Passwords do not match",
    },
    weight: "Weight",
    year: "Year",
    yes: "Yes",
    player:"Player",
  },
  comparePlayers: {
    attributes: "Attributes",
    changePlayer: "Change player",
    contributionOnMarketValue: "Contribution on Market Value",
    details: "Details",
    removePlayer: "Remove Player",
    searchForPlayerToCompare: "Search and select players in the same position for comparison",
    selectPlayer: "Select player",
    sortAttributes: "Sort attributes",
    sortBy: "A to Z | Z to A",
    sortHighLow: "High to low | Low to high",
    topFourHighlights: "Game Statistics current season",
  },
  error: {
    actions: {
      goBack: "Back home",
      refresh: "Refresh",
      title: "What would you like to do?",
    },
    notFound: {
      description: "It looks like the page you're looking for is unavailable or has been replaced.",
    },
    unauthorized: {
      description: "You do not have permission to view this page. Please contact support.",
    },
    forbidden: {
      description: "You do not have permission to perform the requested action. Please contact support.",
    },
    serverError: {
      description: "Sorry! There seems to be an internal server error. Try refreshing the page or choose an action below.",
    },
    serviceUnavailable: {
      description: "The server is currently unable to handle the request due to a temporary overloading or maintenance of the server.",
    },
    unknownError: {
      description: "An unknown error has occurred. We’re sorry for this. Please try refreshing your page, or contact support.",
    },
    badRequest: {
      description: "The request could not be understood by the server due to malformed syntax.",
    },
    invalidToken: {
      description: "The link you are trying to access is either invalid or has expired. Please try resending it or contact support.",
    },
  },
  login: {
    title: "Login",
    emailPlaceholder: "name{'@'}email.com",
    password: "Password",
    passwordPlaceholder: "Please enter your Password.",
    forgotPassword: "Forgot password?",
    checkEmailTitle: "Reset email sent",
    checkEmailParagraph: "Please check your email and click ‘reset password’ in order to set a new password.",
  },
  navigation: {
    home: "Home",
  },
  navigationUser: {
    viewProfile: "View profile",
  },
  profile: {
    address: "Address",
    addressPlaceholder: "Enter address",
    clubName: "Club name",
    clubNamePlaceholder: "Enter club name",
    emailPlaceholder: "Enter email",
    namePlaceholder: "Enter name",
    payments: "Payments",
    profileUpdated: "Profile information updated successfully",
    resetPassword: "Change password",
    surname: "Surname",
    surnamePlaceholder: "Enter surname",
    title: "Profile",
  },
  search: {
    clubs: "Clubs",
    players: "Players",
    searchForClubs: "Search for clubs",
    searchForPlayers: "Search for players",
    title: "Search",
    notFound: "No results found",
    filter: {
      durationOfContract: "Duration of contract",
      playerAdvisor: "Player Advisor",
    },
  },
  results: {
    contractLength: "Contract till",
    marketValueTrend: "Market value trend",
    marketValueOneYearTrend: "Trend 1Y",
    noPlayerDataFound: "No player data found",
    previousSearches: "Previous searches",
    searchResultsFor: "Search results | Search results for",
    sortBy: "Sort by",
    totalClubMarketValue: "Total market value",
  },
  message: {
    warning: "Dear Gool users, Due to maintenance work, there are temporarily no market value updates. Best regards, your Gool team",
    unreliable: "Attention: The market value for this player is currently unreliable."
  },
  viewPlayer: {
    advancedBreakdown: "Detailed market value drivers",
    advisor: "Advisor",
    attributeAverage: "Attribute (Driver)",
    averageContribution: "Benchmark (⌀)",
    averageValue: " Benchmark (⌀)",
    chance: "loss",
    chanceOfValueChanging: "Probability of a loss (>10%, >25% and >50%) of market value within the next two years.",
    changeClub: "Change club",
    changeClubSubTitle: "Search for a club to simulate a change",
    contractUntil: "Contract until",
    contributionOfMarketValue: "Driver value",
    contributingValues: "Contributing Values",
    currentMarketValue: "Current MV",
    dateOfBirth: "Date of birth",
    downloadPDF: "Download PDF",
    downloadPlayerReport: "Download player report",
    downloadPlayerReportPopupHeading: "What would you like to do?",
    downloadXLS: "Download XLS",
    lastUpdated: "Last updated",
    marketValue: "Market value",
    marketValueAbbreviated: "Player",
    marketValueAverageAbbreviated: "Benchmark",
    marketValueDevelopment: "Market value development",
    marketValueDistribution: "Market value distribution",
    marketValueFullHistory: "Full History",
    marketValueLast6Months: "Last 6 Months",
    marketValuePercentTooltip: "This column constitutes of positive and negative values that add up to 100%",
    marketValueSelectTimePeriod: "Select Time Period",
    matchdays: "Historic MV",
    nationalTeam: "National team",
    pastMarketValue: "Past MV",
    playerBreakdown: "Market value driver cluster",
    playersInBenchmark: "players in Benchmark",
    playerMarketValue: "Player MV",
    playingPosition: "Playing position",
    position: "Position",
    predictedMarketValue: "Forcasted MV",
    printOnly: {
      totalAssists: "Total assists",
      totalCleanSheet: "Total clean sheets",
      totalGoals: "Total goals scored",
      totalGoalsConceded: "Total goals conceded",
      totalMatchesPlayed: "Total matches played",
      totalMinutesPlayed: "Total minutes played",
    },
    secondPlayingPosition: "Second playing position",
    tooltips: {
      totalAssists: "Total assists in current season",
      totalCleanSheet: "Total clean sheets in current season",
      totalGoals: "Total goals scored in current season",
      totalGoalsConceded: "Total goals conceded in current season",
      totalMatchesPlayed: "Total matches played in current season",
      totalMinutesPlayed: "Total minutes played in current season",
      age: "Age +/- 2 years"
    },
  },
};
